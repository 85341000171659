<template>
  <div id="app">
    <router-view/>
    <footer-block/>
  </div>
</template>
<script>
import FooterBlock from '@/components/Footer.vue';

require('@/assets/scss/core.scss');

export default {
  components: {
    FooterBlock,
  },
};
</script>
